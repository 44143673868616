import * as i from 'types';
import * as React from 'react';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';

import { CheckMark } from 'common/svg';
import { PricingCard, useCheckoutContext } from 'modules/PricingPage';
import { formatPrice, triggerGtagEvent } from 'services';
import { useAuthenticationUser } from 'services/hooks';

import {
  ExtendedHeading, PriceHeading, Column, PerMembershipText, ValidToText, BenefitContainer,
  BenefitIconContainer, ExtendedButton, CommitmentText,
} from './styled';

export const MembershipCard: React.FC<MembershipCardProps> = ({ item }) => {
  const { handleOpenModal } = useCheckoutContext();
  const { navigate, originalPath } = useI18next();
  const { t } = useTranslation();
  const { authenticated } = useAuthenticationUser();
  const price = formatPrice(item.groupPrice?.amount || item.price.amount);
  const smallDescription = item?.description?.substring(
    item?.description.indexOf('[') + 1,
    item?.description.lastIndexOf(']'),
  );
  const benefits = item?.description?.replace(`[${smallDescription}]`, '')
    .split('<br/>')
    .join('')
    .split('*');
  const name = item.name.split('[')[0];

  const handleModal = (item: i.Serie) => {
    if (!authenticated) {
      return navigate('/register',
        {
          state: {
            redirectUrl: originalPath,
          },
        });
    }

    // JSON Template for GTAG
    // Google default payload, unused fields disabled
    const eventPayload = {
      currency: item.price.currency,
      value: item.price.amount,
      items: [
        {
          item_id: item.id,
          item_name: item.name,
          // affiliation: 'affiliation',
          // coupon: 'coupon',
          // discount: 'discount',
          index: 0,
          // item_brand: 'item_brand',
          item_category: item.seriesType,
          // item_list_id: 'item_list_id',
          // item_list_name: 'item_list_name',
          // item_variant: 'item_variant',
          // location_id: 'location_id',
          price: item.price,
          quantity: 1,
        },
      ],
    };
    triggerGtagEvent('add_to_cart', eventPayload);
    handleOpenModal(item);
  };

  return (
    <PricingCard name={item.name}>
      <Column gap="14px">
        <ExtendedHeading
          upperCase
          variant="black"
          margin="0"
          as="h2"
        >
          {name}
        </ExtendedHeading>
        <Column>
          <PriceHeading
            variant="black"
            as="h3"
            margin="0 8px"
          >
            {price}
          </PriceHeading>
          <CommitmentText>
            {t('per 4 weeks')}
          </CommitmentText>
        </Column>
      </Column>
      <Column>
        {benefits?.map((benefit) => (
          <BenefitContainer key={benefit}>
            <BenefitIconContainer>
              <CheckMark />
            </BenefitIconContainer>
            <PerMembershipText>{benefit}</PerMembershipText>
          </BenefitContainer>
        ))}
      </Column>
      <ExtendedButton
        variant="CTA"
        onClick={() => handleModal(item)}
      >
        {t('buy now')}
      </ExtendedButton>
      <ValidToText>{smallDescription}</ValidToText>
    </PricingCard>
  );
};

type MembershipCardProps = {
  item: i.Serie;
};
