import styled, { css } from 'styled-components';

import { media } from 'styles/utils';

export const UspContainer = styled.div<UspContainerProps>`
  padding: 40px 0 0;
  position: relative;
  margin: 0 0 60px;

  ${media.tablet<UspContainerProps>`
    display: flex;
    padding: 0;
    justify-content: ${({ alignRight }) => alignRight ? 'flex-start' : 'flex-end'};
    margin: 0 0 120px;
  `}

  ${media.desktop`
    padding: 112px 0 0;
  `}
`;

type UspContainerProps = {
  alignRight: boolean;
};

export const UspImage = styled.div<UspImageProps>`
  margin: 0 24px;
  aspect-ratio: 1/1;
  position: relative;

  background: ${({ src }) => `url(${src})`};
  background-size: cover;
  background-position: center;

  ${media.tablet<UspImageProps>`
    position: absolute;
    top: 0;
    left: 0;
    width: 40%;
    bottom: 0;
    height: auto;
    aspect-ratio: unset;

    ${({ alignRight }) => alignRight && css`
      left: auto;
      right: 0;
    `}
  `}

  ${media.desktop`
    height: 700px;
    width: 50%;
  `}
`;

type UspImageProps = {
  src?: string;
  alignRight?: boolean;
};

export const UspContent = styled.div<UspContainerProps>`
  button {
    margin: 0 auto;
  }

  ${media.tablet`
    button {
      margin: 0;
    }
  `}

  ${media.desktop<UspContainerProps>`
    padding: ${({ alignRight }) => alignRight ? '0 60px 0 0' : '0 0 0 60px'};
    max-width: 700px;
  `}
`;

export const UspContentContainer = styled.div<UspContainerProps>`
  background: ${({ theme }) => theme.colors.gray.light};
  margin-top: -120px;
  padding: 160px 24px 24px;

  ${media.tablet`
    margin: 0;
    padding: 20px 40px 40px;
    width: 60%;
  `}

  ${media.desktop<UspContainerProps>`
    min-height: 700px;
    padding: ${({ alignRight }) => alignRight ? '80px 10% 0' : '80px 40px 0 10%'};
  `}
`;

