import * as i from 'types';
import * as React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { Button } from 'common/interaction';
import { Heading, Text } from 'common/typography';
import { getUserClasses } from 'ducks/me';
import { PromotionClass, PersonalTrainingBlock, ProductsBlock } from 'modules/PricingPage';
import { getExpiryLabel } from 'services';
import { useSelector, useAuthenticationUser, useDispatch } from 'services/hooks';
import PersonalTrainingBanner from 'images/personalTraining.png';

import { Layout, HeaderContainer } from './styled';

export const ClassesBlock = ({ isContentBlock, classes }: ClassesBlockProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { determined, authenticated } = useAuthenticationUser();
  const promotionClass = useSelector((state) => state.series.data?.promotions?.[0]);
  const expiryLabel = getExpiryLabel(promotionClass?.expirationInterval, promotionClass?.expiresIn);
  const [showClasses, setShowClasses] = React.useState(false);

  React.useEffect(() => {
    if (determined && authenticated) {
      dispatch(getUserClasses());
    }
  }, [determined]);

  if (classes?.length === 0) return null;

  return (
    <>
      <Layout isContentBlock={isContentBlock}>
        <HeaderContainer>
          {showClasses ? (
            <>
              <Heading as="h2" variant="black" margin="0" upperCase>{t('classes')}</Heading>
              <Text variant="gray" $lineHeight="24">{t('buy single classes which are valid within a certain time period')}</Text>
            </>
          ) : (
            <>
              <Heading as="h2" variant="black" margin="0" upperCase>{t('other options')}</Heading>
              <Text variant="gray" $lineHeight="24">{t('Not what you are looking for? We also offer packages if you want to join some classes')}</Text>
              <Button
                variant="outlined"
                size="fullWidth"
                onClick={() => setShowClasses(true)}
              >
                {t('view more options')}
              </Button>
            </>
          )}
        </HeaderContainer>
        {showClasses && (
          <>
            <ProductsBlock isContentBlock={isContentBlock} items={classes} />
            {promotionClass && (
              <PromotionClass
                title={promotionClass.name}
                subTitle={`${t('valid for')} ${expiryLabel}`}
                serie={promotionClass}
                backgroundImg={PersonalTrainingBanner}
              />
            )}
          </>
        )}
      </Layout>
      {showClasses && <PersonalTrainingBlock />}
    </>
  );
};

type ClassesBlockProps = {
  content?: i.ComponentFormBlock;
  isContentBlock?: boolean;
  classes: i.Serie[];
};
