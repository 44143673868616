import * as i from 'types';
import * as React from 'react';

import { ClassCard, PricingCardWrapper } from 'modules/PricingPage';

export const ProductsBlock = ({ isContentBlock, items }: Props) => {
  const isCentered = items.length === 1;

  return (
    <PricingCardWrapper {...{ isCentered, isContentBlock }}>
      {items.map((item) => (
        <ClassCard item={item} key={item.id} />
      ))}
    </PricingCardWrapper>
  );
};

type Props = {
  items: i.Serie[];
  isContentBlock?: boolean;
};
