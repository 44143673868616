import * as i from 'types';
import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { starterClassesDataSelector } from 'selectors/classes';
import { userClassesData, userClassesDataLoading } from 'selectors/me';
import { selectActiveContract } from 'selectors/meSeries';
import { api } from 'services/api';
import { useSelector } from 'services/hooks';

export const useGetPromoContent = () => {
  const activeContract = useSelector(selectActiveContract);
  const starterClasses = useSelector(starterClassesDataSelector);
  const userBookedClasses = useSelector(userClassesData) || [];
  const isUserBookedClassesLoading = useSelector(userClassesDataLoading);
  const [promotionContent, setPromotionContent] = React.useState<PromoBlockType>();
  const [userType, setUserType] = React.useState<i.PromoBlockType>();

  const { allContentfulComponentPromoBlock: { edges: promoBlocks } } = useStaticQuery<GatsbyTypes.PromoBlockQuery>(graphql`
    query PromoBlock {
      allContentfulComponentPromoBlock {
        edges {
          node {
            id
            type
            title
            subtitle
            image {
              file {
                url
              }
            }
            serieId
          }
        }
      }
    }
  `);

  const checkUserType = () => {
    if (activeContract?.unlimited) return 'gold-member';
    if (starterClasses && userBookedClasses.length === 0) return 'first-timer';
    if (userBookedClasses.length > 0) return 'not-first-timer';
  };

  const getSerie = async (seriesId: string) => {
    const serie = await api.get({ path: `/proxy/zingfit/series/${seriesId}` });

    return serie;
  };

  React.useEffect(() => {
    (async () => {
      if (!isUserBookedClassesLoading) {
        const userType = checkUserType();
        setUserType(userType);

        const promoBlock = promoBlocks.find(({ node }) => node.type === userType)?.node as PromoBlockType;

        if (promoBlock && !promotionContent) {
          const serie = await getSerie(promoBlock?.serieId);

          if (serie) {
            promoBlock.serie = serie;
            setPromotionContent(promoBlock);
          }
        }
      }
    })();
  }, [starterClasses, userBookedClasses, isUserBookedClassesLoading]);

  return {
    promotionContent,
    userType,
  };
};

type PromoBlockType = {
  id: string;
  image: i.MediaType;
  serie: i.Serie;
  serieId: string;
  subtitle: string;
  title: string;
  type: i.PromoBlockType;
  label?: string;
};
