import * as i from 'types';
import * as React from 'react';
import { graphql } from 'gatsby';

import { ContentContainer } from 'common/layout';
import {
  ClassesBlock, CheckoutModal, MembershipsBlock, MembershipsBlockMobile, CheckoutProvider,
  ProductsBlock,
} from 'modules/PricingPage';
import { api } from 'services/api';
import { useDevice } from 'services/hooks';

export const PricingBlock: React.FC<PricingBlockProps> = ({ content }) => {
  const { isMobile } = useDevice();
  const [series, setSeries] = React.useState<i.Serie[]>([]);
  const isCreditPackages = !content.showWorkout && series.every((serie) => serie.expiresIn);

  React.useEffect(() => {
    if (content.ids?.length > 0) {
      const seriePromises = content.ids.map((seriesId) => {
        return api.get<i.Serie>({ path: `/proxy/zingfit/series/${seriesId}`, withAuth: false })
          .then((serie) => {
            const price = Math.round(serie?.price.amount * 1.09);

            return {
              ...serie,
              price: {
                amount: price,
                currency: serie?.price.currency,
              },
            };
          });
      });

      // Use allSettled instead of all so we can ignore failed requests
      Promise.allSettled(seriePromises)
        .then((results) => {
          // Filter succesful requests
          const series = results.filter((result) => result.status === 'fulfilled') as PromiseFulfilledResult<i.Serie>[];
          setSeries(series.map((result) => result.value));
        });
    }
  }, []);

  return (
    <ContentContainer>
      <CheckoutProvider>
        {isCreditPackages ? (
          <ProductsBlock items={series} isContentBlock />
        ) : content.showWorkout ? (
          <ClassesBlock classes={series} isContentBlock />
        ) : isMobile ? (
          <MembershipsBlockMobile memberships={series} isContentBlock />
        ) : (
          <MembershipsBlock memberships={series}  isContentBlock />
        )}
        <CheckoutModal />
      </CheckoutProvider>
    </ContentContainer>
  );
};

export const query = graphql`
  fragment PricingBlockFragment on ContentfulComponentPricingBlock {
    id
    title
    ids
    showWorkout
  }
`;

type PricingBlockProps = {
  content: i.ComponentPricingBlock;
};
